<template>
  <div class="common-detail-view W100">
    <DetailTop :businessResp="businessResp" :personalResp="personalResp"/>
    <TrendPublish :detaildata="detaildata" v-if="isPictureType==='pic'"/>
    <VideoPublish :detaildata="detaildata" v-else/>
  </div>
</template>

<script>
import * as Content from '@/api/content/video.api'
import DetailTop from '../../components/detail-top.vue'
import TrendPublish from '../common/trend-publish.vue'
import VideoPublish from '../common/video-publish.vue'

export default {
  name: 'creatorVideoDynamicDetail',
  components: {
    DetailTop,
    TrendPublish,
    VideoPublish
  },
  data () {
    return {
      id: 0,
      detaildata: {},
      businessResp: {},
      personalResp: {},
      isPictureType: ''
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.isPictureType = this.$route.query.isPic
    this.queryData()
  },
  methods: {
    queryData () {
      Content.detail(this.id)
        .then(res => {
          this.detaildata = res.data || {}
          this.businessResp = res.data.businessResp || {}
          this.personalResp = res.data.personalResp || {}
        })
        .finally(() => {
        })
    }
  }
}
</script>
<style>
.create-bk-card{
  margin: 20px 0 20px 0;
}
.bk_creater{
  width:100%;
  height: 400px;
  background:  rgb(237, 240, 245) url(../../../assets/bk_creater.png) 0 0 no-repeat;
}
.bussiness{
  margin-top:20px;
}
.fileList .ak-viewer-wrap .preview-thumbnail-list .list-item img{
  width: 300px !important;
  height: 400px !important;
}
</style>>
<style lang="less" scoped>
.common-detail-view {
  .header-info-main {
    padding: 20px;
    .C666 {
      display: inline-block;
      width: 90px;
      text-align: right;
    }
  }
}
.left-detail-card{
  width: 100%
}
/deep/ .bindUser .ant-card-body{
  padding: 12px 0 6px 24px;
}
.user-info{
  margin-top: 20px;
  .user-info-content{
    padding: 20px;
    .user-info-certification{
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}
.ant-col{margin-bottom: 8px;}
.ant-col:last-child{margin-bottom: 0;}
// .ant-row{
//   margin-bottom: 8px;
// }
.ant-row:last-child{margin-bottom: 0;}
</style>
